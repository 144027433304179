import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = (props) => {
  let title = "404 Page Not Found"
  let location = props.location.pathname
  return (
    <Layout>
      <Seo title={title} location={location} />
      <div className="text-black font-bold pt-24 text-center pb-96 xl:pt-40 bg-offblue text-white px-10">
        <p className="text-3xl md:text-6xl xl:text-7xl uppercase font-pixeboy mt-64 max-w-5xl mx-auto">
          Sorry this page does not exist! If you expect the path you followed to work, please contact us immediately!
        </p>
        <Link to="/" title="Go to Pyxel Development Home Page" alt="Go to Pyxel Development Home Page">
          <button className="mt-10 relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
            <span>Home Page</span>
          </button>
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
